import { Grid } from '@material-ui/core';
import { Itinerary, UploadedImage } from '@tripr/common';
import React from 'react';
import { Field } from 'react-final-form';
import { FormList } from '../../common/FormList';
import { MyTextField } from '../../common/forms/TextField';
import { MultiImageUploader } from '../../common/MultiImageUploader';
import { ImagesApi } from '../../../api/ImagesApi';

const uploadAccommodationImg = async (file: File): Promise<UploadedImage> => new ImagesApi().uploadImage('itinerary', file);

export const ItineraryList: React.FC<{ name: string }> = ({ name }) => (
  <FormList<Itinerary>
    className="itinerary"
    name={name}
    newValue={() => ({
      day: 0,
      headline: '',
      description: '',
      photos: [],
      totalDistance: null,
      totalTime: null,
      meals: null,
      accommodation: null,
      elevationLoss: null,
    })}
    render={p => (
      <Grid container spacing={2}>
        <Field component={MyTextField} name={`${p.parentName}.day`} label="Day" xs={2} variant={'filled'} type={'number'} />
        <Field component={MyTextField} name={`${p.parentName}.headline`} label="Headline" xs={10} multiline variant={'filled'} />
        <Field component={MyTextField} name={`${p.parentName}.description`} label="Description" xs={12} multiline variant={'filled'} />
        <Field component={MyTextField} name={`${p.parentName}.totalDistance`} label="Total distance" placeholder={'ex: 5.8 miles'} xs={6} variant={'filled'} />
        <Field component={MyTextField} name={`${p.parentName}.totalTime`} label="Total time" xs={6} placeholder={'ex: 4-6 hours'} variant={'filled'} />
        <Field component={MyTextField} name={`${p.parentName}.elevationLoss`} label="Elevation loss" xs={6} placeholder={'ex: 755ft'} variant={'filled'} />
        <Field component={MyTextField} name={`${p.parentName}.meals`} label="Meals" xs={6} placeholder={'ex: Breakfast, Lunch, Dinner'} variant={'filled'} />
        <Field component={MyTextField} name={`${p.parentName}.accommodation`} label="Accommodation" xs={12} placeholder={'ex: Tent'} variant={'filled'} />
        <MultiImageUploader name={`${p.parentName}.photos`} uploader={uploadAccommodationImg} size={[240, 240]} />
      </Grid>
    )}
  />
);
